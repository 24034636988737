import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { selectUser } from "../../features/userSlice";
import { MenuItem, Select } from "@mui/material";
import { getSalesTeam } from "../Admin/Utilities/api";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <input
      value={globalFilter || ""}
      onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      placeholder="Search table..."
      style={{
        marginBottom: "10px",
        padding: "8px",
        width: "100%",
        border: "1px solid gray",
        borderRadius: "4px",
        fontSize: "1rem",
      }}
    />
  );
};

const highlightText = (text, search) => {
  if (!search || !text) return text;
  const regex = new RegExp(`(${search})`, "gi");
  return text.replace(
    regex,
    (match) => `<span style="background-color: yellow;">${match}</span>`
  );
};

const SalesScheduleTable = ({ data, tableType }) => {
  const columns = useMemo(
    () => [
      { Header: "Job #", accessor: "Job #" },
      { Header: "Customer", accessor: "Customer" },
      { Header: "Job Name", accessor: "Job Name" },
      { Header: "Address", accessor: "Address" },
      {
        Header: "Canceled",
        accessor: "Canceled",
        Cell: ({ value }) => {
          return value === "false" || value === false ? "✅" : "❌";
        },
      },
      {
        Header: "Can Date",
        accessor: "Can Date",
        Cell: ({ value }) => {
          const date = new Date(value);

          if (isNaN(date) || date.getFullYear() === 1969) {
            return "N/A";
          }
          return date.toLocaleDateString();
        },
      },
      { Header: "Estimator", accessor: "Estimator" },
      { Header: "Designer", accessor: "Designer" },
      {
        Header: "Quote In",
        accessor: "Quote In",
        Cell: ({ value }) => {
          const date = new Date(value);

          if (isNaN(date) || date.getFullYear() === 1969) {
            return "N/A";
          }
          return date.toLocaleDateString();
        },
      },
      {
        Header: "Quote By Date",
        accessor: "Quote By Date",
        Cell: ({ value }) => {
          const date = new Date(value);

          if (isNaN(date) || date.getFullYear() === 1969) {
            return "N/A";
          }
          return date.toLocaleDateString();
        },
      },
      { Header: "Lost? Why?", accessor: "Lost? Why?" },
      {
        Header: "Quote Done",
        accessor: "Quote Done",
        Cell: ({ value }) => {
          const date = new Date(value);

          if (isNaN(date) || date.getFullYear() === 1969) {
            return "N/A";
          }
          return date.toLocaleDateString();
        },
      },
      {
        Header: "Order Date",
        accessor: "Order Date",
        Cell: ({ value }) => {
          const date = new Date(value);

          if (isNaN(date) || date.getFullYear() === 1969) {
            return "N/A";
          }
          return date.toLocaleDateString();
        },
      },
      {
        Header: "Date Designed",
        accessor: "Date Designed",
        Cell: ({ value }) => {
          const date = new Date(value);

          if (isNaN(date) || date.getFullYear() === 1969) {
            return "N/A";
          }
          return date.toLocaleDateString();
        },
      },
      {
        Header: "Approved",
        accessor: "Approved",
        Cell: ({ value }) => {
          const date = new Date(value);

          if (isNaN(date) || date.getFullYear() === 1969) {
            return "N/A";
          }
          return date.toLocaleDateString();
        },
      },
      {
        Header: "In Prod",
        accessor: "In Prod",
        Cell: ({ value }) => {
          return value === "false" || value === false ? "✅" : "❌";
        },
      },
      {
        Header: "BLT",
        accessor: "BLT",
        Cell: ({ value }) => {
          return value === "false" || value === false ? "✅" : "❌";
        },
      },
      {
        Header: "On Hold",
        accessor: "On Hold",
        Cell: ({ value }) => {
          return value === "false" || value === false ? "✅" : "❌";
        },
      },
    ],
    [tableType]
  );

  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const searchValue = state.globalFilter || "";

  return (
    <div>
      <GlobalFilter
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table
        {...getTableProps()}
        style={{
          border: "solid 1px gray",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    borderBottom: "solid 3px gray",
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const originalValue = cell.value;
                  const highlightedValue =
                    typeof originalValue === "string"
                      ? highlightText(originalValue, searchValue)
                      : originalValue;

                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                        background: "#e4e3e2",
                      }}
                      dangerouslySetInnerHTML={{ __html: highlightedValue }}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const SalesSchedule = () => {
  const [schedule, setSchedule] = useState([]);
  const [salesTeam, setSalesTeam] = useState([]);
  const userIDRef = useRef(null); // Use useRef to store userID persistently
  const user = useSelector(selectUser);

  const formatDates = (data) => {
    return data.map((item) => {
      const formattedItem = { ...item };
      Object.keys(formattedItem).forEach((key) => {
        const value = formattedItem[key];
        const date = new Date(value);
        if (!isNaN(date) && date.getFullYear() !== 1969) {
          formattedItem[key] = date.toLocaleDateString();
        }
      });
      return formattedItem;
    });
  };

  const getSchedule = () => {
    return fetch(`/api/sbcf/SalesSchedule?id=${userIDRef.current}`)
      .then((response) => response.json())
      .then((responseJson) => {
        const formattedData = formatDates(responseJson.recordset);
        setSchedule(formattedData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (event) => {
    userIDRef.current = event.target.value; // Update userIDRef instead of state
    getSchedule(); // Trigger data fetch with new userID
  };

  useEffect(() => {
    getSalesTeam(setSalesTeam);
    if (!userIDRef.current) {
      userIDRef.current = user.userID; // Set the initial userIDRef value
    }
  }, [user]);

  useEffect(() => {
    if (userIDRef.current) {
      getSchedule();
    }
  }, [userIDRef.current]); // Trigger the schedule fetch when userID changes

  const quoteData = schedule.filter((item) => item.Status === "Quote");
  const orderData = schedule.filter((item) => item.Status === "Order");

  return (
    <div className="flex flex-col w-full p-4">
      <h1 className="font-black text-xl">
        Sales Schedule - ID {userIDRef.current}
      </h1>
      {salesTeam.length > 0 && (
        <div className="mb-2">
          <Select
            value={userIDRef.current || ""}
            onChange={handleChange}
            className="w-full bg-white"
          >
            {salesTeam.map((item, index) => (
              <MenuItem key={index} value={item.UserID}>
                {item.Name} - {item.Branch}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div className="flex flex-col w-full overflow-auto">
        <h2 className="font-black text-lg">Quotes</h2>
        {quoteData.length > 0 ? (
          <SalesScheduleTable data={quoteData} tableType="Quote" />
        ) : (
          <p>No Quotes Found</p>
        )}
        <h2 className="font-black text-lg">Orders</h2>
        {orderData.length > 0 ? (
          <SalesScheduleTable data={orderData} tableType="Order" />
        ) : (
          <p>No Orders Found</p>
        )}
      </div>
    </div>
  );
};

export default SalesSchedule;
