import React, { useState, useEffect } from "react";
import { deleteFeedback, getFeedback } from "./Utilities/api";
import { normalizeDate } from "../../utilities";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LoadingSpinner from "../../components/Other/LoadingSpinner";

const DisplayFeedback = () => {
  const [feedbackEntries, setFeedbackEntries] = useState([]);

  const [formData, setFormData] = useState({
    key: "0kb4947sfwyj1zzcm34cbn9e7flmd8f3",
  });

  useEffect(() => {
    getFeedback(setFeedbackEntries);
  }, []);

  return (
    <div className="flex flex-col">
      {feedbackEntries.length > 0 ? (
        <table className="w-full m-4 dark:text-white rounded-md">
          <thead>
            <tr className="dark:bg-gray-800 bg-gray-500">
              <th className="p-4"></th>
              <th className="p-4 text-center w-1/6">Name</th>
              <th className="p-4 text-center w-1/6">Date</th>
              <th className="p-4 text-center">Feedback</th>
              <th className="p-4 text-center">Score</th>
            </tr>
          </thead>
          <tbody>
            {feedbackEntries.map((entry, index) => (
              <tr
                key={index}
                className={
                  index % 2 === 0
                    ? "dark:bg-gray-700 bg-gray-300"
                    : "dark:bg-gray-800 bg-white"
                }
              >
                <td className="w-min">
                  <button>
                    <DeleteForeverIcon
                      style={{ color: "red", marginLeft: "0.5rem" }}
                      onClick={() => {
                        deleteFeedback({ id: entry._id });
                        getFeedback(setFeedbackEntries);
                      }}
                    />
                  </button>
                </td>
                <td className="p-4 text-center w-1/6">
                  {entry.submittedBy.name}
                </td>
                <td className="p-4 text-center w-1/6">
                  {normalizeDate(entry.date)}
                </td>
                <td className="p-4 text-center">{entry.feedback}</td>
                <td className="p-4 text-center">{entry.feedbackValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>
          <LoadingSpinner />
        </p>
      )}
    </div>
  );
};

export default DisplayFeedback;
