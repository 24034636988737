"use client";

import React, { useEffect, useState } from "react";
import { useTable } from "react-table";

const ReviewsTable = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch all reviews from the server
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch("/api/cloud/website/getSurveys");
        if (!response.ok) {
          throw new Error("Failed to fetch reviews");
        }
        const data = await response.json();
        setReviews(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setErrorMessage("Unable to load reviews. Please try again.");
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const updateApproval = async (id, status) => {
    try {
      const response = await fetch("/api/cloud/website/approveReview", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ reviewId: id, approved: status }),
      });

      if (response.ok) {
        setReviews(
          reviews.map((review) =>
            review._id === id ? { ...review, approved: status } : review
          )
        );
      } else {
        const errorData = await response.json();
        alert(`Failed to update review: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error updating review approval status:", error);
      alert("An unexpected error occurred.");
    }
  };

  // Table columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Recommend",
        accessor: "recommend",
      },
      {
        Header: "Feedback",
        accessor: "feedback1",
      },
      {
        Header: "Actions",
        Cell: ({ row }) =>
          row.original.approved === 1 ? (
            <div className="flex items-center gap-2">
              <span className="text-green-500 font-bold">Review Approved</span>
              <button
                onClick={() => updateApproval(row.original._id, 0)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
              >
                Set to Unapproved
              </button>
            </div>
          ) : (
            <button
              onClick={() => updateApproval(row.original._id, 1)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Approve
            </button>
          ),
      },
    ],
    [reviews]
  );

  const tableInstance = useTable({ columns, data: reviews });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Manage Reviews</h1>
      {loading && <p>Loading...</p>}
      {errorMessage && (
        <div className="p-4 mb-4 text-red-700 bg-red-100 rounded">
          {errorMessage}
        </div>
      )}
      {!loading && reviews.length === 0 && <p>No reviews to display.</p>}
      {!loading && reviews.length > 0 && (
        <table
          {...getTableProps()}
          className="min-w-full border border-gray-200 rounded-lg"
        >
          <thead className="bg-gray-100">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="px-4 py-2 text-left text-sm font-medium text-gray-700"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="border-b last:border-0 hover:bg-gray-50"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 py-2 text-sm text-gray-700"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ReviewsTable;
